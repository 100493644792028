<template>
  <v-card>
    <v-card-title>
      Users
      <v-icon class="ml-2" :color="siteColor" @click="addUser">mdi-account-plus-outline</v-icon>
      <div class="flex-grow-1"></div>
      <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table
      v-if="users.length"
      :headers="headers"
      :items="users"
      :items-per-page="30"
      item-key="id"
      class="elevation-1"
      :search="search"
      sort-by="email"
    >
      <template v-slot:body="{items}">
        <tbody>
          <template v-for="(item,i) in items">
            <tr :key="item.id">
              <!-- <td>{{ item.email }}</td> -->
              <td>
                <v-edit-dialog
                    :return-value.sync="item.email"
                    @save="editItem(item)"
                    @open="setItem(item)"
                    large
                    :color="siteColor"
                  >
                    <v-text-field
                    :color="siteColor"
                      v-model="item.email"
                      hide-details
                    ></v-text-field>
                    <template v-slot:input>
                      <v-text-field
                      :color="siteColor"
                        v-model="item.email"
                        hide-details
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
              </td>
              <td>{{ item.first }}</td>
              <td>{{ item.last }}</td>
              <td><v-select @change="changeUserAuth({id: item.id, auth: item.auth, location: item.location ? item.location.id : null})" :color="siteColor" single-line hide-details :items="authProfiles" v-model="item.auth"></v-select></td>
              <!-- <td>{{ item.admin }}</td> -->
              <td><v-select :readonly="item.auth !== 'admin'" @click.native.stop="" clearable @change="updateUserLocation({id: item.id, location: item.location ? item.location.id : null})" :color="siteColor" single-line hide-details return-object :items="locations" item-text="name" v-model="item.location"></v-select></td>
              <!-- <td>{{ item.degreePref ? item.degreePref : "Fahrenheit" }}</td> -->
              <td><v-select @change="changeDegree({id: item.id, prop: 'degreePref', value: item.degreePref, user: item})" :color="siteColor" item-text="name" single-line hide-details :items="tempProfiles" v-model="item.degreePref"></v-select></td>
              <!-- <td><v-select @click.native.stop="" @change="changeUserLocation({id: item.id, prop: 'userLocationId', value: item.location ? item.location.id : null})" item-text="name" single-line return-object hide-details :items="locations" v-model="item.location"></v-select></td> -->
              <!-- <td>{{ item.status }}</td> -->
              <td>{{ item.sensors.length }}</td>
              <!-- <td>{{ capitalize(item.distributors) }}</td>
              <td>{{ capitalize(item.owner) }}</td>-->
              <!-- <td>
                <div v-for="(loc,i) in item.locations" :key="i">{{ capitalize(loc) }}</div>
              </td>-->
              <!-- <td>{{ item.approved ? 'Yes' : 'No' }}</td> -->
              <!-- <td v-if="isSuper || isDistributor">
                <v-btn
                  dark
                  x-small
                  v-if="!item.approved"
                  @click="approveUser(item.id, item.email)"
                >Approve</v-btn>
                <v-btn
                  dark
                  x-small
                  v-else-if="item.approved && item.auth !== 'super' && item.email !== user.attributes.email"
                  @click="disableUser(item.id)"
                >Disable</v-btn>
              </td> -->
            </tr>
          </template>
        </tbody>
      </template>
    </v-data-table>
    <!-- <td>
      <v-edit-dialog
        :return-value.sync="item.name"
        @save="editItem(item, 'name', i, $event)"
        @open="setItem(item)"
        large
        :color="siteColor"
      >
        <v-text-field
        :color="siteColor"
          v-model="item.name"
          hide-details
        ></v-text-field>
        <template v-slot:input>
          <v-text-field
          :color="siteColor"
            v-model="item.name"
            hide-details
            :readonly="isReadOnly"
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </td> -->
  </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "users",
  data() {
    return {
      search: "",
      currentItem: "",
      tempProfiles: ["Fahrenheit", "Celsius"],
      authProfiles: ["super", "admin", "customer"],
      headers: [
      { text: "Email", value: "email" },
      { text: "First Name", value: "first" },
      {
        text: "Last Name",
        value: "last"
      },
      { text: "User Type", value: "auth", width: "10%" },
      { text: "Location", value: "location" },
      { text: "Degree Pref", value: "degreePref", width: "10%" },
      // { text: "Location", value: "locations" },
      // { text: "Status", value: "status" },
      { text: "Sensors", value: "sensors" },
        // { text: "Distributor", value: "distributors" },
        // { text: "Owner", value: "owner" },
        // { text: "Locations", value: "locations" },
        // { text: "Approved", value: "approved" },
        // { text: "", value: "" }
      ]
    };
  },
  async created() {
    if (this.isSuper) {
      this.$store.dispatch("user/getAllUsers");
      await this.$store.dispatch("location/allLocations")
    } else if (this.isDistributor) {
      this.$store.dispatch(
        "user/getDistributorUsers",
        this.userDistributorName
      );
    } else if (this.isStore) {
      this.$store.dispatch("user/getStoreUsers", this.userStoreName);
    }
  },
  computed: {
    ...mapState("user", ["user", "users", "userDetails"]),
    ...mapState("location", ["location", "locations"]),
    ...mapGetters("user", [
      "isSuper",
      "isDistributor",
      "isStore",
      "userDistributorName",
      "userStoreName"
    ])
  },
  methods: {
    async addUser() {
      this.$swal({
      title: 'Add User',
      input: "text",
      inputLabel: "User Name",
      showCancelButton: true,
      confirmButtonColor: '#4baf4f',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Add',
      'iconColor': '#d33',
      inputValue: "",
      inputValidator: (value) => {
        if (!value) {
          return "You need to enter user details!";
        }
      }
      }).then(async (result) => {
        if (result.isConfirmed) {
          // await this.$store.dispatch("location/addLocation", result.value);
        }
      });
      
    },
    async editItem(item) {
      await this.$store.dispatch("user/adminUpdateUserEmail", {
        id: item.id,
        email: item.email
      });
    },
    setItem(item) {
      this.currentItem = { ...item }
    },
    async changeUserAuth(val) {
      await this.$store.dispatch("user/adminUpdateUserAuth", {
        id: val.id,
        auth: val.auth.toLowerCase()
      });
      if (val.location) {
        await this.updateUserLocation({
          id: val.id,
          location: null
        })
      }
    },
    async updateUserLocation(location) {
      await this.$store.dispatch("user/updateUserLocation", location);
    },
    async changeDegree(val) {
      if (val.user.auth === "customer") {
        val.auth = "customer"
        val.sensors = val.user.sensors
      }
      else if (val.user.store) {
        val.auth = "store"
        val.sensors = val.user.store
      }
      await this.$store.dispatch("user/updateUserPropertySimple", val)
    },
    async changeUserLocation(val) {
      await this.$store.dispatch("sensor/updateSensorPropertySimple", val)
    },
    async approveUser(id, email) {
      await this.$store.dispatch("user/approveUserRegistration", { id, email });
    },
    async disableUser(id) {
      await this.$store.dispatch("user/disableUserRegistration", id);
    },
    capitalize(word) {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    }
  }
};
</script>

<style lang="scss" scoped>
tr {
  cursor: pointer;
}
table {
  td {
    .v-text-field {
      border-color: #ffffff1f;
      padding-top: 0px;
      margin-top: 0px;
      font-size: 14px;
    }
  }
}
</style>